import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { appActions, userActions } from "../../actions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import HeaderPlain from "../../components/HeaderPlain";
import { withStyles } from "@mui/styles";
import OtpDialog from "./OtpDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Util from "../../utils/Util";
import Enums from "../../utils/Enums.js";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import jsSHA from "jssha";
import config from "../../config/config";
import PopupNotice from "../../components/PopupNotice";
import AlertTopBar from "../../components/AlertTopBar";
import CiamResponseCode from "../../utils/CiamResponseCode";

const AFTERLOGIN = "/auth/dashboard";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOTP: false,
      username: "",
      password: "",
      showForgotPassword: false,
      showFirstTimeLogin: false,
      firstTimeLoginSubmitting: false,
      firstTimeLoginError: null,
      isLoggingIn: false,
      ciamErrorCode: "",
      submitOTPErrorMessage: ""
    };
  }
  componentDidMount() {
    if (this.props.user.error) {
      this.props.user.error = null;
    }
  }

  componentWillMount() {
    if (this.props.user.loggedIn) {
      if (this.props.history.location.pathname === "/") {
        // logged in go to auth
        this.props.history.push(AFTERLOGIN);
      } else this.props.history.goBack(); //prevent logged in go to login page
    } else {
      this.props.appActions.reset();
    }
  }

  loginClick = () => {
    if (this.state.username.trim() === "") return;
    if (this.state.password.trim() === "") return;
    this.setState({ isLoggingIn: true, submitOTPErrorMessage: "" });
    this.doCIAMLogin();
  };

  doCIAMLogin = () => {
    const { CIAMLogin, getRSA } = this.props;
    let shaObj = new jsSHA("SHA-1", "TEXT");

    getRSA(
      this.state.username.trim().toLowerCase(),
      this.state.password.trim().toLowerCase()
    )
      .then(
        resp => {
          if (resp.success) {
            var myObjectInstance = new PACSCryptography(resp.data.public_key);
            var encryptedPW = myObjectInstance.encryptPassword(
              this.state.password
            );

            this.setState({
              public_key: resp.data.public_key,
              tx_id: resp.data.tx_id,
              random_key: resp.data.random_key,
              encrypted: encryptedPW,
              rsaErrorMessage: null
            });
            const cnonce = Util.generateUUID();
            const chash = Util.getCHash(resp.data.public_key, cnonce);
            //do login
            return CIAMLogin(
              this.state.username.trim().toLowerCase(),
              this.state.encrypted,
              this.state.random_key,
              this.state.tx_id,
              cnonce,
              chash
            );
          }
        },
        error => {
          console.log("RSA Error : ", error);
          if (error.code !== 500) {
            this.setState({
              rsaErrorMessage: error.message || error.defaultMessage
            });
          }
          return Promise.reject(error);
        }
      )
      .then(
        response => {
          if (response.success) {
            const tokenObj = Util.jwt_decode(response.data.access_token);
            if (
              tokenObj &&
              tokenObj.scope &&
              tokenObj.scope.includes(Enums.SCOPE_TYPE.OTP_W)
            ) {
              this.setState({ showOTP: true });
            } else {
              this.props.updateToken(response.data);
              this.handleLoginSuccess(AFTERLOGIN);
            }
          }
        },
        error => {
          console.error("Login error:", error.customMessage);
          this.setState({ isLoggingIn: false });
        }
      );
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  };

  handleCloseOtpDialog(otpMessage) {
    let errorMessage = "";

    if (
      otpMessage &&
      typeof otpMessage === "object" &&
      otpMessage.isCloseOtpDialog
    ) {
      errorMessage = otpMessage.customMessage || "";
    } else if (typeof otpMessage === "boolean") {
      errorMessage = otpMessage ? this.state.submitOTPErrorMessage : "";
    }

    this.setState({
      showOTP: false,
      submitOTPError: false,
      submitOTPErrorMessage: errorMessage,
      isLoggingIn: false,
      ciamErrorCode: ""
    });
  }

  enterAndSubmit = event => {
    if (event.key === "Enter") {
      this.loginClick();
    }
  };

  handleOpenCloseForgotPassword() {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  }

  handleSubmitOTP(otp) {
    const { encrypted, random_key, tx_id } = this.state;
    this.props.postOTP(otp, encrypted, random_key, tx_id).then(
      response => {
        if (response.success) {
          this.props.updateToken(response.data);
          this.handleCloseOtpDialog(false);
          this.handleLoginSuccess(AFTERLOGIN);
        }
      },
      error => {
        this.setState({
          submitOTPError: true,
          submitOTPErrorMessage: error.customMessage,
          ciamErrorCode: error.message
        });
      }
    );
  }

  handleAgreeTnc = (marketingConsent, tnc) => {
    marketingConsent = marketingConsent ? marketingConsent : false;
    this.props.agreeTnc({ marketingConsent, tnc }).then(
      response => {
        this.setState(
          {
            firstTimeLoginSubmitting: false,
            showFirstTimeLogin: false
          },
          () => {
            this.handleLoginSuccess(AFTERLOGIN);
          }
        );
      },
      error => {
        console.error("Agree TNC error:", error);
        this.setState({ isLoggingIn: false });
      }
    );
  };

  handleLoginSuccess = url => {
    this.setState({ isLoggingIn: false });
    this.props.history.push(url);
  };

  render() {
    const {
      classes,
      user: { error }
    } = this.props;
    const {
      showOTP,
      showForgotPassword,
      isLoggingIn,
      ciamErrorCode,
      submitOTPErrorMessage
    } = this.state;

    const errorCodesToHandle = [
      CiamResponseCode.MAX_OTP_FAILED.code,
      CiamResponseCode.FORGEROCK_ERROR.code
    ];

    let shouldShowError = error !== null;
    let errorMessage = shouldShowError
      ? error.customMessage || error.defaultMessage
      : "";

    if (errorCodesToHandle.includes(ciamErrorCode)) {
      this.handleCloseOtpDialog(true);
    }
    if (error === null || errorCodesToHandle.includes(ciamErrorCode)) {
      shouldShowError = true;
      errorMessage = submitOTPErrorMessage;
    }

    return (
      <div className="login">
        {config.appVersionEnabled && (
          <div>
            <Typography
              variant="body2"
              className={
                config.bannerEnabled == true
                  ? classes.version
                  : classes.versionWithoutBanner
              }
            >
              {config.appVersionNumber}
            </Typography>
          </div>
        )}
        {config.bannerEnabled && (
          <div
            className={
              config.appVersionEnabled == true
                ? classes.bannerDisplay
                : classes.bannerDisplayWithoutVersion
            }
          >
            <AlertTopBar
              displayLogo={config.logoDisplay}
              type="tip"
              title={config.bannerTitle}
              listDescription={config.bannerDetails}
            />
          </div>
        )}
        <HeaderPlain />
        <div
          className={
            config.bannerEnabled == true
              ? classes.wrapperBanner
              : classes.wrapper
          }
        >
          <Paper className={classes.root} elevation={2}>
            <div style={{ padding: 20 }}>
              <Typography variant="title" className={classes.titleBoxText}>
                Log in
              </Typography>
            </div>

            <div>
              {shouldShowError && (
                <Typography
                  variant="subheading"
                  className={classes.errorMessage}
                >
                  {errorMessage}
                </Typography>
              )}
              <div style={{ padding: "10px 15%" }}>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  placeholder="Enter Username"
                  value={this.state.username}
                  margin="normal"
                  onChange={this.handleInputChange}
                  onKeyPress={this.enterAndSubmit}
                  variant="standard"
                />
              </div>
              <div style={{ padding: "10px 15%" }}>
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  placeholder="Enter Password"
                  type="password"
                  value={this.state.password}
                  margin="normal"
                  onChange={this.handleInputChange}
                  onKeyPress={this.enterAndSubmit}
                  autoComplete="new-password"
                  variant="standard"
                />
              </div>
            </div>

            <Fade
              in={isLoggingIn}
              style={{
                transitionDelay: isLoggingIn ? "40ms" : "0ms"
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <div style={{ padding: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.loginClick}
                disabled={isLoggingIn}
              >
                LOG IN
              </Button>
            </div>
            <br />
            <Button
              color="black"
              className={classes.forgotPassword}
              onClick={this.handleOpenCloseForgotPassword.bind(this)}
            >
              Forgot password
            </Button>
          </Paper>
        </div>

        <OtpDialog
          show={showOTP}
          submitError={this.state.submitOTPError}
          submitErrorMessage={this.state.submitOTPErrorMessage}
          submitHandler={this.handleSubmitOTP.bind(this)}
          closeHandler={this.handleCloseOtpDialog.bind(this)}
          ciamErrorCode={this.state.ciamErrorCode}
        />

        <ForgotPasswordDialog
          show={showForgotPassword}
          forgotPassword={this.props.forgotPassword}
          closeHandler={this.handleOpenCloseForgotPassword.bind(this)}
        />
        {config.popUpLoginMessageEnabled && (
          <PopupNotice message={config.popUpLoginMessage} />
        )}
      </div>
    );
  }
}

const styles = theme => {
  return {
    wrapper: {
      position: "absolute",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 550,
      height: 400,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: 300,
        height: 310
      }
    },
    wrapperBanner: {
      position: "flex",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 550,
      height: 400,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: 300,
        height: 310
      }
    },
    root: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5)
    },
    forgot: {
      textAlign: "center",
      marginTop: 20
    },
    errorMessage: {
      marginBottom: theme.spacing.unit,
      color: theme.palette.error.main
    },
    bannerDisplay: {
      marginTop: "100px",
      maxWidth: "90%",
      marginLeft: "5%",
      marginBottom: "5%"
    },
    wrapperBanner: {
      position: "flex",
      margin: "auto",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 550,
      height: 400,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: 300,
        height: 310
      }
    },
    root: {
      paddingTop: 20,
      paddingBottom: 20
    },
    forgot: {
      textAlign: "center",
      marginTop: 20
    },
    errorMessage: {
      marginBottom: theme.spacing.unit,
      color: theme.palette.error.main
    },
    bannerDisplay: {
      maxWidth: "90%",
      marginLeft: "5%",
      marginBottom: "5%"
    },
    bannerDisplayWithoutVersion: {
      marginTop: "100px",
      maxWidth: "90%",
      marginLeft: "5%",
      marginBottom: "5%"
    },
    version: {
      paddingTop: "5%",
      paddingRight: "5%",
      textAlign: "right",
      opacity: "50%"
    },
    versionWithoutBanner: {
      paddingTop: "5%",
      paddingRight: "5%",
      paddingBottom: "10%",
      textAlign: "right",
      opacity: "50%"
    }
  };
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login));
