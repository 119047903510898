import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Enums from "../utils/Enums";
import { withStyles } from "@mui/styles";
import {
  Typography,
  TextField,
  Button,
  Tooltip,
  Autocomplete,
  FormControl,
  Box
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { bindActionCreators } from "redux";
import { userActions } from "../actions";
import ReactFormInputValidation from "react-form-input-validation";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import InfoIcon from "@mui/icons-material/Info";
import SnackBar from "../components/SnackBar";
import MessageFormat from "messageformat";
import DOMPurify from "dompurify";
import {
  generalAgeRange,
  siaAgeRange,
  pruAgencyAgeRange,
  pruAgencyMedAgeRange
} from "../config/age-range.constants";
import {
  PruAgencyEnrolment,
  PruAgencyMedEnrolment,
  SiaEnrolment
} from "../utils/EnrolmentTypeUtility";

const FORMAT_DATE = "DD/MM/YYYY";
const styles = theme => ({
  root: { padding: 30 },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    width: "70%"
  },
  occupationField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    width: "70%",
    float: "left"
  },
  menu: {
    width: "70%"
  },

  field: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000"
  },
  header: {
    fontSize: "18px",
    lineHeight: "40px",
    color: "#000000",
    fontWeight: "bold"
  },

  label: {
    fontSize: "18px",
    lineHeight: "55px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.388636px",
    color: "#6B6A6D"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing.unit
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  disclaimer: {
    paddingLeft: "10px",
    fontSize: "16px"
  },

  validationError: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: "OpenSans",
    lineHeight: "1em",
    display: "block"
  },
  errorMessage: {
    marginTop: "20px",
    marginBottom: theme.spacing.unit,
    color: theme.palette.error.main
  },
  customFormControlChildWrapper: {
    display: "flex"
  }
});

const maritalStatus = [
  {
    value: "M",
    label: "Married"
  },
  {
    value: "S",
    label: "Single"
  },
  {
    value: "W",
    label: "Widowed"
  },
  {
    value: "D",
    label: "Divorced"
  }
];

const designation = [
  {
    value: "FC",
    label: "FC/WM/AL/FAL"
  },
  {
    value: "IBFC",
    label: "IBFC"
  },
  {
    value: "Secretary & Admin Staff",
    label: "Secretary & Admin Staff"
  }
];

const gender = [
  {
    value: "M",
    label: "Male"
  },
  {
    value: "F",
    label: "Female"
  }
];

String.prototype.alphaNumeric = function() {
  return this.replace(/[^\w]|_/g, "");
};
String.prototype.numeric = function() {
  return this.replace(/[^0-9]+/g, "");
};

const CustomFormControlChildWrapper = ({
  children,
  hasTooltip,
  tooltipText
}) => {
  return (
    <Box display="flex" alignItems="center" gap="0.25rem">
      <>{children}</>
      <Box pt="0.45rem">
        {hasTooltip ? (
          <Tooltip title={tooltipText} placement="top">
            <InfoIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </Tooltip>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

class EnroleMyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      userEnrole: props.userEnrole,
      content: props.user.content,
      nationality: this.fetchNationality(props),
      type: this.props.type || "Employee",
      errors: {},
      snackBarErrorShow: false
    };

    this.form = new ReactFormInputValidation(this, { locale: "en" });
    this.form.useRules({
      fullName: "required",
      passport_nric: ["required", "between:2,12"],
      email: "required|email",
      employee_no: "required",
      height: "required|numeric|min:1|max:999",
      weight: "required|numeric|min:1|max:999",
      occupation: "required",
      nationality: "required",
      marital_status: "required",
      designation: "required",
      fc_code: ["required", "between:1,5"],
      agency_unit: ["required"],
      dependent: "required",
      gender: "required",
      dob: "required",
      single: null
    });
    this.occupationRef = createRef();
    this.form.onformsubmit = fields => {
      this.handleSave(this.state.type);
    };

    ReactFormInputValidation.registerAsync("username_available", function(
      username,
      attribute,
      req,
      passes
    ) {
      setTimeout(() => {
        if (username === "foo")
          passes(false, "Username has already been taken.");
        // if username is not available
        else passes();
      }, 1000);
    });

    let messages = ReactFormInputValidation.getMessages("en");
    // Override the 'required' validation rule error message.
    messages.required = "This field is required.";
    // Override the existing validation messages for locale 'en'
    ReactFormInputValidation.setMessages("en", messages);
  }

  formatDate = date => {
    return (date && moment(date, FORMAT_DATE)) || null;
  };

  setUserField = (fieldName, value) => {
    if (this.state.type === "Employee") {
      this.setState({
        user: {
          ...this.state.user,
          [fieldName]: value
        }
      });
    } else if (this.state.type === "Dependant") {
      this.setState({
        userEnrole: {
          ...this.state.userEnrole,
          [fieldName]: value
        }
      });
    }
  };

  getUserField = () => {
    if (this.state.type === "Employee") {
      return this.state.user;
    } else if (this.state.type === "Dependant") {
      return this.state.userEnrole;
    }

    return {};
  };

  handleSelectBlur = (event, name) => {
    if (event.target.innerHTML != "") {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: ""
        }
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: "This field is required"
        }
      });
    }
  };

  handleTextBlur = (event, name) => {
    if (event.target.value != "") {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: ""
        }
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: "This field is required"
        }
      });
    }
  };

  validateOccupationField = value => {
    if (
      this.props.user.client_name.config.disableOccupationValidation !==
        "True" &&
      value === "4"
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          occupation: "Your occupation type is ineligible for the coverage"
        }
      });
    } else if (!value) {
      this.setState({
        errors: {
          ...this.state.errors,
          occupation: "This field is required"
        }
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          occupation: ""
        }
      });
    }
  };

  handleOccupationChange = (_event, selectedValue) => {
    this.validateOccupationField(selectedValue?.value);
    this.setUserField("occupation", selectedValue);
  };

  handleChange = name => event => {
    let value;
    let meRegex = /[^\w]|_/g;
    let allowCharactersRegex = /^[A-Za-z0-9 &().@/-]*$/g;
    let isInvalid;
    let isAllowedCharacters;
    if (name === "employee_no" || name === "passport_nric") {
      isInvalid = meRegex.test(event.target.value);
      if (isInvalid) {
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: "Please enter only alphanumeric"
          }
        });
      }
      value = DOMPurify.sanitize(event.target.value.alphaNumeric());
    } else if (name === "height" || name === "weight") {
      value = event.target.value.numeric();
    } else if (name === "fullName") {
      isAllowedCharacters = allowCharactersRegex.test(event.target.value);
      if (!isAllowedCharacters) {
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: "Special characters detected from your input"
          }
        });
        value = event.target.value.replace(/[^A-Za-z0-9 &().@/-]*$/g, "");
      } else {
        value = DOMPurify.sanitize(event.target.value);
      }
    } else {
      value = event.target.value;
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: ""
        }
      });
    }

    this.setUserField(name, value);
  };

  onFocus = name => event => {
    if (event.target.value === "") {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: "This field is required"
        }
      });
    }
  };

  get isEmployee() {
    return this.props.type === "Employee";
  }

  get isDependant() {
    return this.props.type === "Dependant";
  }

  get isChild() {
    return this.state.userEnrole.dependent === "CHILD";
  }

  get isSpouse() {
    return this.state.userEnrole.dependent === "SPOUSE";
  }

  get isPruAgencyEnrolment() {
    return this.props.user.client_name.config.isPruAgencyEnrolment === "True";
  }

  get isPruAgencyMedEnrolment() {
    return (
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
    );
  }

  get isSiaEnrolment() {
    return this.props.user.client_name.config.isSiaEnrolment === "True";
  }

  /**
    @param {number} diffDays
    @param {number} diffYears
    @param {{
      isPruAgencyEnrolment: boolean
      isPruAgencyMedEnrolment: boolean
      isSiaEnrolment: boolean
      isExistingInsured: boolean
    }} enrolmentConfig 
   */
  validateChildAgeEligibility = (diffDays, diffYears, enrolmentConfig) => {
    if (enrolmentConfig.isSiaEnrolment) {
      return SiaEnrolment.validateChildAge(diffDays, diffYears);
    }

    return {
      result: Boolean(
        diffDays >= generalAgeRange.dependant.child.min.value &&
          diffYears <= generalAgeRange.dependant.child.max.value
      ),
      ageRange: generalAgeRange.dependant.child
    };
  };

  /**
    @param {number} diffYears 
    @param {{
      isPruAgencyEnrolment: boolean
      isPruAgencyMedEnrolment: boolean
      isSiaEnrolment: boolean
      isExistingInsured: boolean
    }} enrolmentConfig 
   */
  validateSpouseAgeEligibility = (diffYears, enrolmentConfig) => {
    console.log("enrolmentConfig", enrolmentConfig);
    if (enrolmentConfig.isPruAgencyEnrolment) {
      return PruAgencyEnrolment.validateSpouseAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    if (enrolmentConfig.isPruAgencyMedEnrolment) {
      return PruAgencyMedEnrolment.validateSpouseAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    if (enrolmentConfig.isSiaEnrolment) {
      return SiaEnrolment.validateSpouseAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    return {
      result: Boolean(
        diffYears >= generalAgeRange.dependant.spouse.min.value &&
          diffYears <= generalAgeRange.dependant.spouse.max.value
      ),
      ageRange: generalAgeRange.dependant.spouse
    };
  };

  /**
    @param {number} diffYears 
    @param {{
      isPruAgencyEnrolment: boolean
      isPruAgencyMedEnrolment: boolean
      isSiaEnrolment: boolean
      isExistingInsured: boolean
    }} enrolmentConfig 
   */
  validateEmployeeAgeEligibility = (diffYears, enrolmentConfig) => {
    if (enrolmentConfig.isPruAgencyEnrolment) {
      return PruAgencyEnrolment.validateEmployeeAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    if (enrolmentConfig.isPruAgencyMedEnrolment) {
      return PruAgencyMedEnrolment.validateEmployeeAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    if (enrolmentConfig.isSiaEnrolment) {
      return SiaEnrolment.validateEmployeeAge(
        diffYears,
        enrolmentConfig.isExistingInsured
      );
    }

    return {
      result: Boolean(
        diffYears >= generalAgeRange.employee.min.value &&
          diffYears <= generalAgeRange.employee.max.value
      ),
      ageRange: generalAgeRange.employee
    };
  };

  /**
    @param {moment.Moment} date
   */
  validateAge = date => {
    const a = moment(date, "DD/MM/YYYY");
    let b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }

    const diffYears = b.diff(a, "years");
    const diffDays = b.diff(a, "days");

    if (this.isDependant) {
      console.log("this.state.userEnrole", this.state.userEnrole);
      const enrolmentConfig = {
        isPruAgencyEnrolment: this.isPruAgencyEnrolment,
        isPruAgencyMedEnrolment: this.isPruAgencyMedEnrolment,
        isSiaEnrolment: this.isSiaEnrolment,
        isExistingInsured: this.isSiaEnrolment ? this.state.user.existing_insured : this.state.userEnrole.existing_insured
      };

      if (this.isChild)
        return this.validateChildAgeEligibility(
          diffDays,
          diffYears,
          enrolmentConfig
        );
      if (this.isSpouse)
        return this.validateSpouseAgeEligibility(diffYears, enrolmentConfig);
    }

    if (
      this.props.user.client_name.config.disableEmployeeAgeValidation !==
        "True" &&
      this.isEmployee
    ) {
      return this.validateEmployeeAgeEligibility(diffYears, {
        isPruAgencyEnrolment: this.isPruAgencyEnrolment,
        isPruAgencyMedEnrolment: this.isPruAgencyMedEnrolment,
        isSiaEnrolment: this.isSiaEnrolment,
        isExistingInsured: this.state.user.existing_insured
      });
    }

    return {
      result: false,
      ageRange: {
        min: {
          value: 0,
          unit: "age_unit"
        },
        max: {
          value: 0,
          unit: "age_unit"
        }
      }
    };
  };

  /**
    @param {string} name 
    @returns {(date: moment.Moment) => void} 
   */
  handleDateChange = name => date => {
    let message = "";
    const { result: isEligible } = this.validateAge(date);
    if (!isEligible) {
      message = this.isEmployee
        ? "Your age is ineligible for the coverage"
        : "Your age is ineligible for the coverage. Refer to info icon for more info.";
    }

    this.setState({
      errors: {
        ...this.state.errors,
        [name]: message
      }
    });

    this.setUserField(name, date ? moment(date).format(FORMAT_DATE) : null);
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  fetchNationality = propss => {
    let strNationality = "user.nationality";
    let nationalityArr = new Array();
    let data = propss.intl.messages;
    for (var prop in data) {
      if (prop.includes(strNationality)) {
        let nationalityObj = { value: "", label: "" };
        nationalityObj["value"] = prop.split(".")[2];
        nationalityObj["label"] = data[prop];
        nationalityArr.push(nationalityObj);
      }
    }
    return nationalityArr;
  };

  handleSave = userType => {
    let dob =
      userType === "Employee"
        ? moment(this.state.user.dob, "DD/MM/YYYY")
        : moment(this.state.userEnrole.dob, "DD/MM/YYYY");
    this.handleDateChange("dob")(dob);
    let errorCount = 0;
    for (let error in this.state.errors) {
      if (this.state.errors.hasOwnProperty(error)) {
        let value = this.state.errors[error];
        if (value.trim() != "") {
          errorCount++;
        }
      }
    }

    if (errorCount > 0) {
      return;
    }

    if (userType === "Employee") {
      let user = {};
      user.id_no = this.state.user.passport_nric;
      user.full_name = this.state.user.fullName;
      user.occupation =
        !this.isPruAgencyEnrolment &&
        !this.isPruAgencyMedEnrolment &&
        this.state.user.occupation?.label
          ? this.state.user.occupation.label
          : this.state.user.occupation;
      user.status = Enums.STATUS.PENDING;
      user.id = this.state.user.id;
      user.marital_status = this.state.user.marital_status;
      user.designation = this.state.user.designation;
      user.agency_unit = this.state.user.agency_unit;
      user.fc_code = this.state.user.fc_code;
      user.dob = this.state.user.dob;
      user.email = this.state.user.email;
      user.gender = this.state.user.gender;
      user.nationality = this.state.user.nationality;
      user.country_of_residence = this.state.user.country_of_residence;
      user.accountId = this.state.user.accountId;
      user.category = this.state.user.category;
      user.employee_no = this.state.user.employee_no;
      user.height = this.state.user.height;
      user.weight = this.state.user.weight;
      user.bmi = this.state.user.bmi;
      user.existing_insured = this.state.user.existing_insured;

      this.props.updateMe(user).then(
        response => {
          let questionDepMap = new Map(this.props.user.questionDepMap);
          let selectedValue = new Map(this.props.user.selectedValue);
          let mySelectMap = new Map(this.props.user.planSelected);
          let myDependentSelectMap = new Map(
            this.props.user.planSelectedDependent
          );
          questionDepMap.delete(user.id);
          selectedValue.delete(user.id);
          this.props.selectHDFValue(questionDepMap);
          this.props.selectHDFRemarks(selectedValue);

          this.props.user.currentPlans.map(planHead => {
            mySelectMap.delete(planHead.planType);
            this.props.user.dependencies.map(dependent => {
              this.resetDepRecords(dependent.id);
            });
          });

          this.props.selectPlan(mySelectMap);
          this.props.buttonName === "Save"
            ? this.props.history.push("/auth/dashboard/updatePlan")
            : this.props.history.push("/auth/registerConfirmation", "Register");
        },
        error => {
          this.setState({
            snackBarErrorShow: true,
            triggerLoadingPopup: false,
            errors: error
          });
        }
      );
    } else if (userType === "Dependant") {
      let userEnrole = {};
      userEnrole.id_no = this.state.userEnrole.id_no;
      userEnrole.full_name = this.state.userEnrole.full_name;
      // userEnrole.occupation = this.state.userEnrole.occupation.label ? this.state.userEnrole.occupation.label : this.state.userEnrole.occupation;
      userEnrole.id = this.state.userEnrole.id;
      userEnrole.marital_status = this.state.userEnrole.marital_status;
      userEnrole.dob = this.state.userEnrole.dob;
      userEnrole.email = this.state.userEnrole.email;
      userEnrole.gender = this.state.userEnrole.gender;
      userEnrole.nationality = this.state.userEnrole.nationality;
      userEnrole.country_of_residence = this.state.userEnrole.country_of_residence;
      userEnrole.category = this.state.userEnrole.category;
      userEnrole.height = this.state.userEnrole.height;
      userEnrole.weight = this.state.userEnrole.weight;
      userEnrole.bmi = this.state.userEnrole.bmi;
      userEnrole.dependent = this.capitalizeFLetter(
        this.state.userEnrole.dependent
      );
      userEnrole.occupation =
        !(this.isPruAgencyEnrolment || this.isPruAgencyMedEnrolment) &&
        this.state.userEnrole.occupation?.label
          ? this.state.userEnrole.occupation.label
          : this.state.userEnrole.occupation;
      userEnrole.existing_insured = this.isPruAgencyEnrolment
        ? false
        : this.state.userEnrole.existing_insured;
      if (userEnrole.id === undefined || userEnrole.id === null) {
        this.props.saveDependent(this.props.user.id, userEnrole).then(
          response => {
            this.props.updateRecord();
            this.resetDepRecords(userEnrole.id);
            this.props.onClose();
          },
          error => {
            this.setState({
              snackBarErrorShow: true,
              triggerLoadingPopup: false,
              errors: error
            });
          }
        );
      } else {
        this.props.updateDependent(this.props.user.id, userEnrole).then(
          response => {
            // this.props.updateRecord(userEnrole);
            this.resetDepRecords(userEnrole.id);
            this.props.onClose();
          },
          error => {
            this.setState({
              snackBarErrorShow: true,
              triggerLoadingPopup: false,
              errors: error
            });
          }
        );
      }
    }
  };

  resetDepRecords = dependentId => {
    if (this.props.user.underwriting_required === true) {
      let questionDepMap = new Map(this.props.user.questionDepMap);
      let selectedValue = new Map(this.props.user.selectedValue);
      if (questionDepMap.has(dependentId)) {
        questionDepMap.delete(dependentId);
      }
      if (selectedValue.has(dependentId)) {
        selectedValue.delete(dependentId);
      }
      this.props.selectHDFValue(questionDepMap);
      this.props.selectHDFRemarks(selectedValue);
    }
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let dependentOptOutName = new Map();
    if (this.props.user.dependentOptOutName !== undefined) {
      dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    }
    let myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    let mySelectMap = new Map(this.props.user.planSelected);
    let tempPlaonObjList = [];
    this.props.user.currentPlans.map(planHead => {
      mySelectMap.delete(planHead.planType);
      myDependentSelectMap.delete(planHead.planType);
      dependentOptOutName = new Map();
      showDependentBadge = new Map();
    });
    this.props.handleBadgeOnDependent(showDependentBadge);
    this.props.setDependentOptOutName(dependentOptOutName);
    this.props.selectPlan(mySelectMap);
    this.props.selectDependentPlan(myDependentSelectMap);
  };

  occupationToClassValue = occupationLabel => {
    let valueObj = {};
    let occupationObj;
    if (typeof occupationLabel === "object" && occupationLabel !== null) {
      occupationObj =
        this.props.user.occupationClassList &&
        this.props.user.occupationClassList.find(
          occupation => occupationLabel.label === occupation.occupation
        );
      valueObj.value = occupationObj.occupation_class;
      valueObj.label = occupationObj.occupation;
    } else if (
      occupationLabel !== undefined &&
      occupationLabel !== "" &&
      occupationLabel != null
    ) {
      occupationObj =
        this.props.user.occupationClassList &&
        this.props.user.occupationClassList.find(
          occupation => occupationLabel === occupation.occupation
        );
      valueObj.value = occupationObj && occupationObj.occupation_class;
      valueObj.label = occupationObj && occupationObj.occupation;
    } else {
      valueObj.value = "";
      valueObj.label = "";
    }

    return valueObj.label;
  };

  getDependantAgeRange = () => {
    if (this.isChild) {
      if (this.isSiaEnrolment) {
        return siaAgeRange.dependant.child.general;
      }

      return generalAgeRange.dependant.child;
    }

    if (this.isSpouse) {
      const isExistingInsured = this.state.userEnrole.existing_insured;

      if (this.isPruAgencyEnrolment) {
        console.log("this.state.userEnrole2", this.state.userEnrole);
        console.log("isExistingInsured", isExistingInsured);
        if (isExistingInsured) {
          return pruAgencyAgeRange.dependant.spouse.existingInsured;
        }

        return pruAgencyAgeRange.dependant.spouse.nonExistingInsured;
      }

      if (this.isPruAgencyMedEnrolment) {
        if (isExistingInsured) {
          return pruAgencyMedAgeRange.dependant.spouse.existingInsured;
        }

        return pruAgencyMedAgeRange.dependant.spouse.nonExistingInsured;
      }

      if (this.isSiaEnrolment) {
        if (isExistingInsured) {
          return siaAgeRange.dependant.spouse.existingInsured;
        }

        return siaAgeRange.dependant.spouse.nonExistingInsured;
      }
    }

    return generalAgeRange.dependant.spouse;
  };

  /**
    @param {MessageFormat} messageFormat 
   */
  getDateTooltipDesc = messageFormat => {
    const { labels } = this.state.content;
    if (this.isDependant && labels) {
      const ageRange = this.getDependantAgeRange();

      return messageFormat.compile(labels["dashboard.view.entryAge.tooltip"])({
        dependent_type: this.isChild ? "child" : "spouse",
        min_value: ageRange.min.value,
        min_value_unit: ageRange.min.unit,
        max_value: ageRange.max.value,
        max_value_unit: ageRange.max.unit
      });
    }

    return "";
  };

  render() {
    let disabledEmployeeEdit = false;
    let disabledDependentEdit = false;
    let disclaimer;
    let supportBillingEmail = "";
    const { classes, type, theme, history } = this.props;
    const { user, nationality, userEnrole } = this.state;
    const labels = this.state.content && this.state.content.labels;
    const mf = new MessageFormat("en");
    const dateTooltipDesc = this.getDateTooltipDesc(mf);

    let isSpouseRegistered = false;
    user.dependencies.map(dependent => {
      if (dependent.dependent === "SPOUSE") {
        isSpouseRegistered = true;
      }
    });

    let dependent = [];
    if (type === "Dependant") {
      if (user.client_name.config.isEnroleSpouseOnly === "True") {
        dependent = [
          {
            value: "SPOUSE",
            label: "Spouse"
          }
        ];
      } else if (
        this.state.userEnrole.dependent === "SPOUSE" ||
        ([null, undefined].includes(this.state.userEnrole.dependent) &&
          isSpouseRegistered === false) ||
        isSpouseRegistered === false
      ) {
        dependent = [
          {
            value: "SPOUSE",
            label: "Spouse"
          },
          {
            value: "CHILD",
            label: "Child"
          }
        ];
      } else if (
        this.props.user.marital_status != "M" &&
        isSpouseRegistered === true
      ) {
        dependent = [
          {
            value: "CHILD",
            label: "Child"
          }
        ];
      } else {
        dependent = [
          {
            value: "CHILD",
            label: "Child"
          }
        ];
      }
    }

    if (this.isPruAgencyEnrolment || this.isPruAgencyMedEnrolment) {
      supportBillingEmail = user.client_name.config.supportBillingEmail;

      disabledEmployeeEdit = user.existing_insured === true ? true : false;

      disabledDependentEdit =
        user.dependencies.length > 0 &&
        type === "Dependant" &&
        this.state.userEnrole.existing_insured === true
          ? true
          : false;

      if (type === "Employee" && user.existing_insured === true) {
        disclaimer = labels && mf.compile(labels["step1.employee.disclaimer"]);
      } else if (
        type === "Dependant" &&
        this.state.userEnrole &&
        this.state.userEnrole.existing_insured === true
      ) {
        disclaimer = labels && mf.compile(labels["step1.dependant.disclaimer"]);
      }
    }

    return (
      <div className={classes.root}>
        {this.state.errors != "" ? (
          <Typography
            component="div"
            variant="subheading"
            className={classes.errorMessage}
          >
            {this.state.errors.message}
          </Typography>
        ) : (
          " "
        )}
        <Typography component="div" className={classes.header}>
          {this.props.title}
        </Typography>
        <form
          className="myForm"
          noValidate
          autoComplete="off"
          onSubmit={this.form.handleSubmit}
        >
          <Grid container spacing={"24px"}>
            {type === "Dependant" && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  disabled={
                    type === "Employee"
                      ? disabledEmployeeEdit
                      : disabledDependentEdit
                  }
                  id="dependent"
                  name="dependent"
                  select
                  label="Relationship"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Select your relation type"
                  value={
                    userEnrole.dependent === null ||
                    userEnrole.dependent === undefined
                      ? ""
                      : userEnrole.dependent
                  }
                  onChange={this.handleChange("dependent")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  data-attribute-name="Relationship"
                >
                  {dependent.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <label className={classes.validationError}>
                  {this.state.errors.dependent
                    ? this.state.errors.dependent
                    : // ? "This field is required"
                      ""}
                </label>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                required
                disabled={
                  type === "Employee"
                    ? disabledEmployeeEdit
                    : disabledDependentEdit
                }
                id="fullName"
                name="fullName"
                label={`${type} Full Name (as in NRIC)`}
                data-attribute-name="Full Name"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 200
                }}
                onBlur={event => this.handleTextBlur(event, "fullName")}
                placeholder="Enter your full name"
                className={classes.textField}
                margin="normal"
                value={
                  type === "Dependant"
                    ? userEnrole.full_name === null
                      ? ""
                      : userEnrole.full_name
                    : user.fullName === null
                    ? ""
                    : user.fullName
                }
                onChange={
                  type === "Dependant"
                    ? this.handleChange("full_name")
                    : this.handleChange("fullName")
                }
              />
              <label className={classes.validationError}>
                {this.state.errors.fullName ? this.state.errors.fullName : ""}
              </label>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                required
                disabled={
                  type === "Employee"
                    ? disabledEmployeeEdit
                    : disabledDependentEdit
                }
                autoComplete="[off]"
                name="passport_nric"
                id="passport_nric"
                label={
                  type === "Dependant"
                    ? `${type} NRIC/FIN/BC/Passport`
                    : `${type} NRIC/FIN`
                }
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 12
                }}
                className={classes.textField}
                value={
                  type === "Dependant"
                    ? userEnrole.id_no === null
                      ? ""
                      : userEnrole.id_no
                    : user.passport_nric === null
                    ? ""
                    : user.passport_nric
                }
                onChange={
                  type === "Dependant"
                    ? this.handleChange("id_no")
                    : this.handleChange("passport_nric")
                }
                onBlur={event => this.handleTextBlur(event, "passport_nric")}
                margin="normal"
                placeholder={
                  type === "Dependant"
                    ? "Enter your NRIC/FIN/BC/Passport"
                    : "Enter your NRIC/FIN"
                }
                data-attribute-name="NRIC/FIN"
              />
              <label className={classes.validationError}>
                {this.state.errors.passport_nric
                  ? this.state.errors.passport_nric
                  : ""}
              </label>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth margin="normal">
                <CustomFormControlChildWrapper
                  hasTooltip={type === "Dependant"}
                  // title="Child aged 1 to 25 last birthday and spouse aged up to 64 last birthday are eligible"
                  tooltipText={dateTooltipDesc}
                >
                  <DatePicker
                    required
                    disableFuture={true}
                    autoComplete="[off]"
                    clearable
                    label={`${type} Date of Birth`}
                    value={
                      type === "Dependant"
                        ? this.formatDate(userEnrole.dob)
                        : this.formatDate(user.dob)
                    }
                    onChange={this.handleDateChange("dob")}
                    onBlur={event => this.handleTextBlur(event, "dob")}
                    format="DD/MM/YYYY"
                    animateYearScrolling={true}
                    keyboard
                    slotProps={{
                      textField: {
                        className: classes.textField,
                        InputLabelProps: {
                          shrink: true
                        },
                        inputProps: {
                          id: "dob",
                          name: "dob",
                          "data-attribute-name": "dob"
                        }
                      }
                    }}
                    disabled={
                      (type === "Dependant" &&
                        (userEnrole.dependent === null ||
                          userEnrole.dependent === undefined ||
                          userEnrole.dependent === "" ||
                          disabledDependentEdit)) ||
                      (type === "Employee" && disabledEmployeeEdit)
                    }
                    placeholder="DD/MM/YYYY"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </CustomFormControlChildWrapper>
                <label className={classes.validationError}>
                  {this.state.errors.dob ? this.state.errors.dob : ""}
                </label>
              </FormControl>
            </Grid>

            {type === "Employee" && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  type="email"
                  required
                  disabled={true}
                  id="email"
                  name="email"
                  label={
                    type === "Dependant"
                      ? "Dependant Email Address"
                      : "Email Address"
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={this.form.handleBlurEvent}
                  onChange={this.handleChange("email")}
                  placeholder="Enter your email id"
                  className={classes.textField}
                  margin="normal"
                  value={
                    type === "Dependant"
                      ? userEnrole.email === null
                        ? ""
                        : userEnrole.email
                      : user.email === null
                      ? ""
                      : user.email
                  }
                  data-attribute-name="Email Address"
                />
                <label className={classes.validationError}>
                  {this.state.errors.email ? this.state.errors.email : ""}
                </label>
              </Grid>
            )}
            {user.client_name.config.hideEmployeeNo !== "True" &&
              type === "Employee" && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    disabled={
                      type === "Employee"
                        ? disabledEmployeeEdit
                        : disabledDependentEdit
                    }
                    id="employee_no"
                    name="employee_no"
                    label="Employee No."
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      maxLength: 12
                    }}
                    onBlur={event => this.handleTextBlur(event, "employee_no")}
                    onChange={this.handleChange("employee_no")}
                    placeholder="Enter your Employee No."
                    className={classes.textField}
                    margin="normal"
                    value={user.employee_no === null ? "" : user.employee_no}
                    data-attribute-name="Employee No."
                  />
                  <label className={classes.validationError}>
                    {this.state.errors.employee_no
                      ? this.state.errors.employee_no
                      : ""}
                  </label>
                </Grid>
              )}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                required
                disabled={
                  type === "Employee"
                    ? disabledEmployeeEdit
                    : disabledDependentEdit
                }
                select
                InputLabelProps={{
                  shrink: true
                }}
                label="Gender"
                value={
                  type === "Dependant"
                    ? userEnrole.gender === null ||
                      userEnrole.gender === undefined
                      ? ""
                      : userEnrole.gender
                    : user.gender === null || user.gender === null
                    ? ""
                    : user.gender
                }
                onChange={this.handleChange("gender")}
                onBlur={event => this.handleSelectBlur(event, "gender")}
                name="gender"
                className={classes.textField}
                id="gender"
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                placeholder="Select your gender"
                margin="normal"
                data-attribute-name="Gender"
              >
                {gender.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <label className={classes.validationError}>
                {this.state.errors.gender ? this.state.errors.gender : ""}
              </label>
            </Grid>

            {this.props.user.client_name.config.hideCountry !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  disabled={
                    type === "Employee"
                      ? disabledEmployeeEdit
                      : disabledDependentEdit
                  }
                  id="nationality"
                  name="nationality"
                  select
                  label="Country of Citizenship"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={
                    type === "Dependant"
                      ? userEnrole.nationality === null ||
                        userEnrole.nationality === undefined
                        ? ""
                        : userEnrole.nationality
                      : user.nationality === null ||
                        user.nationality === undefined
                      ? ""
                      : user.nationality
                  }
                  onChange={this.handleChange("nationality")}
                  onBlur={event => this.handleSelectBlur(event, "nationality")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  data-attribute-name="Nationality"
                >
                  {nationality.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <label className={classes.validationError}>
                  {this.state.errors.nationality
                    ? this.state.errors.nationality
                    : ""}
                </label>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                required
                disabled={
                  type === "Employee"
                    ? disabledEmployeeEdit
                    : disabledDependentEdit
                }
                id="marital_status"
                name="marital_status"
                select
                label="Marital Status"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                value={
                  type === "Dependant"
                    ? userEnrole.marital_status === null ||
                      userEnrole.marital_status === undefined
                      ? ""
                      : userEnrole.marital_status
                    : user.marital_status === null ||
                      user.marital_status === undefined
                    ? ""
                    : user.marital_status
                }
                onChange={this.handleChange("marital_status")}
                onBlur={event => this.handleSelectBlur(event, "marital_status")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                margin="normal"
                data-attribute-name="Marital Status"
              >
                {maritalStatus.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <label className={classes.validationError}>
                {this.state.errors.marital_status
                  ? this.state.errors.marital_status
                  : ""}
              </label>
            </Grid>
            {user.client_name.config.hideWeightAndHeight !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  id="weight"
                  name="weight"
                  label="Weight (in kg)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={event => this.handleTextBlur(event, "weight")}
                  onChange={this.handleChange("weight")}
                  placeholder="Enter your weight"
                  className={classes.textField}
                  margin="normal"
                  value={
                    type === "Dependant"
                      ? userEnrole.weight === null
                        ? ""
                        : userEnrole.weight
                      : user.weight === null
                      ? ""
                      : user.weight
                  }
                  data-attribute-name="Weight"
                />
                <label className={classes.validationError}>
                  {this.state.errors.weight ? this.state.errors.weight : ""}
                </label>
              </Grid>
            )}

            {user.client_name.config.hideWeightAndHeight !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  required
                  id="height"
                  name="height"
                  label="Height (in cm)"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={event => this.handleTextBlur(event, "height")}
                  onChange={this.handleChange("height")}
                  placeholder="Enter your height"
                  className={classes.textField}
                  margin="normal"
                  value={
                    type === "Dependant"
                      ? userEnrole.height === null
                        ? ""
                        : userEnrole.height
                      : user.height === null
                      ? ""
                      : user.height
                  }
                  data-attribute-name="Height"
                />
                <label className={classes.validationError}>
                  {this.state.errors.height ? this.state.errors.height : ""}
                </label>
              </Grid>
            )}

            {user.client_name.config.hideOccupation !== "True" && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth margin="normal">
                  <CustomFormControlChildWrapper
                    hasTooltip={type === "Dependant"}
                    tooltipText='Please select "child" or "student" if the child is unemployed or "home-maker" if spouse is unemployed.'
                  >
                    <Autocomplete
                      options={
                        this.props.user.occupationClassList &&
                        this.props.user.occupationClassList.map(suggestion => ({
                          value: suggestion.occupation_class,
                          label: suggestion.occupation
                        }))
                      }
                      className={
                        type === "Dependant"
                          ? classes.occupationField
                          : classes.textField
                      }
                      onChange={this.handleOccupationChange}
                      defaultValue={
                        type === "Dependant"
                          ? userEnrole.occupation === null
                            ? ""
                            : userEnrole.occupation
                          : user.occupation === null
                          ? ""
                          : user.occupation
                      }
                      value={this.occupationToClassValue(
                        type === "Dependant"
                          ? userEnrole.occupation === null
                            ? ""
                            : userEnrole.occupation
                          : user.occupation === null
                          ? ""
                          : user.occupation
                      )}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            label="Occupation *"
                            name="occupation"
                            id="occupation"
                            data-attribute-name="Occupation"
                            placeholder="Search your occupation"
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    ></Autocomplete>
                  </CustomFormControlChildWrapper>
                  <label className={classes.validationError}>
                    {this.state.errors.occupation
                      ? this.state.errors.occupation
                      : ""}
                  </label>
                </FormControl>
              </Grid>
            )}

            {type === "Employee" &&
              user.client_name.config.showDesignation === "True" && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    id="designation"
                    name="designation"
                    select
                    label="Designation"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={
                      user.designation === null ||
                      user.designation === undefined
                        ? ""
                        : user.designation
                    }
                    onChange={this.handleChange("designation")}
                    onBlur={event =>
                      this.handleSelectBlur(event, "designation")
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    data-attribute-name="Designation"
                  >
                    {designation.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <label className={classes.validationError}>
                    {this.state.errors.designation
                      ? this.state.errors.designation
                      : ""}
                  </label>
                </Grid>
              )}
            {type === "Employee" &&
              user.designation === "Secretary & Admin Staff" &&
              user.client_name.config.showDesignation === "True" && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    id="agency_unit"
                    name="agency_unit"
                    label="Agency Unit"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      maxLength: 12
                    }}
                    onBlur={event => this.handleTextBlur(event, "agency_unit")}
                    onChange={this.handleChange("agency_unit")}
                    placeholder="Enter your Agency Unit"
                    className={classes.textField}
                    margin="normal"
                    value={user.agency_unit === null ? "" : user.agency_unit}
                    data-attribute-name="Agency Unit"
                  />
                  <label className={classes.validationError}>
                    {this.state.errors.agency_unit
                      ? this.state.errors.agency_unit
                      : ""}
                  </label>
                </Grid>
              )}
            {type === "Employee" &&
              (user.designation === "FC" || user.designation === "IBFC") &&
              user.client_name.config.showDesignation === "True" && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required
                    id="fc_code"
                    name="fc_code"
                    label="FC Code"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      maxLength: 5
                    }}
                    onBlur={event => this.handleTextBlur(event, "fc_code")}
                    onChange={this.handleChange("fc_code")}
                    placeholder="Enter your FC Code"
                    className={classes.textField}
                    margin="normal"
                    value={user.fc_code === null ? "" : user.fc_code}
                    data-attribute-name="FC Code"
                  />
                  <label className={classes.validationError}>
                    {this.state.errors.fc_code ? this.state.errors.fc_code : ""}
                  </label>
                </Grid>
              )}
          </Grid>

          {disclaimer && (
            <div style={{ paddingLeft: "0px" }}>
              <Typography
                component={"span"}
                className={classes.disclaimer}
                dangerouslySetInnerHTML={{
                  __html: disclaimer({
                    supportBillingEmail: supportBillingEmail
                  })
                }}
              />
            </div>
          )}
          <div style={{ padding: "50px" }}>
            {this.props.type !== "Dependant" ? (
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="contained"
                color="secondary"
                style={{ float: "left" }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                onClick={() => this.props.onClose()}
                variant="contained"
                color="secondary"
                style={{ float: "left" }}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              disabled={
                type === "Dependant"
                  ? (this.state.userEnrole.occupation &&
                      this.state.userEnrole.occupation.value === "4" &&
                      this.props.user.client_name.config
                        .disableOccupationValidation !== "True") ||
                    disabledDependentEdit
                  : this.state.user.occupation &&
                    this.state.user.occupation.value === "4" &&
                    this.props.user.client_name.config
                      .disableOccupationValidation !== "True"
              }
              variant="contained"
              color="primary"
              style={{ float: "right" }}
            >
              {this.props.buttonName}
            </Button>
          </div>
        </form>
        <SnackBar
          type="error"
          open={this.state.snackBarErrorShow}
          onClose={() => this.setState({ snackBarErrorShow: false })}
          message="We were unable to submit your enrolment request. Please try again."
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(EnroleMyDetails)));
