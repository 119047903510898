import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleMessageDialog from "../../SimpleMessageDialog";
import _, { set } from "lodash";
import { red } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { Questionnaires } from "../../../config/Questionnaire.config";
import {
  getDifferentiatedValues,
  getUser,
  getUserIndex,
  isOnlyAndMainEmployee,
  hdfUserListHasFemale
} from "../../../utils/HealthDeclarationForm.utils";

import {
  handleUpdateHealthDeclaration,
  handleUpdateHasHDF
} from "../../../redux/health-declaration/hdf.actions";

import { PrivateFlyingQuestionnaire } from "../questionnaire/extreme-sports/PrivateFlyingQuestionnaire.component";
import { ScubaDivingQuestionnaire } from "../questionnaire/extreme-sports/ScubaDivingQuestionnaire.component";
import { MountaineeringQuestionnaire } from "../questionnaire/extreme-sports/MountaineeringQuestionnaire.component";
import { SkyDivingQuestionnaire } from "../questionnaire/extreme-sports/SkyDivingQuestionnaire.component";
import { RacingQuestionnaire } from "../questionnaire/extreme-sports/RacingQuestionnaire.component";
import { OtherExtremeSportsEngagementQuestionnaire } from "../questionnaire/extreme-sports/OtherExtremeSportsEngagementQuestionnaire.component";

import { DrugAlcoholAddictionQuestionnaire } from "../questionnaire/drug-alcohol-addiction/DrugAlcoholAddictionQuestionnaire.component";

import { InsuranceArrangementQuestionnaire } from "../questionnaire/insurance-arrangement/InsuranceArrangementQuestionnaire.component";

import { RecentHealthConditionQuestionnaire } from "../questionnaire/recent-health-condition/RecentHealthConditionQuestionnaire.component";

import { SmokingHabitsQuestionnaire } from "../questionnaire/smoking-habits/SmokingHabitsQuestionnaire.component";

import { AlcoholConsumptionQuestionnaire } from "../questionnaire/alcohol-consumption/AlcoholConsumptionQuestionnaire.component";

import { STDsHistoryQuestionnaire } from "../questionnaire/std/STDsHistoryQuestionnaire.component";

import { HealthScreeningHistoryQuestionnaire } from "../questionnaire/health-screening/HealthScreeningHistoryQuestionnaire.component";

import { FamilyMedicalHistoryQuestionnaire } from "../questionnaire/family-medical-history/FamilyMedicalHistoryQuestionnaire.component";

import { PAPSmearQuestionnaire } from "../questionnaire/female-specific/PAPSmearQuestionnaire.component";
import { GynecologicalQuestionnaire } from "../questionnaire/female-specific/GynecologicalQuestionnaire.component";
import { PregnancySufferingQuestionnaire } from "../questionnaire/female-specific/PregnancySufferingQuestionnaire.component";
import { OtherFemaleOrgansIssuesQuestionnaire } from "../questionnaire/female-specific/OtherFemaleOrgansIssuesQuestionnaire.component";
import { PregnancyConditionQuestionnaire } from "../questionnaire/female-specific/PregnancyConditionQuestionnaire.component";

import { RespiratoryQuestionnaire } from "../questionnaire/other-medical-conditions/RespiratoryQuestionnaire.component";
import { NervousMentalQuestionnaire } from "../questionnaire/other-medical-conditions/NervousMentalQuestionnaire.component";
import { BowelQuestionnaire } from "../questionnaire/other-medical-conditions/BowelQuestionnaire.component";
import { KidneyQuestionnaire } from "../questionnaire/other-medical-conditions/KidneyQuestionnaire.component";
import { EndocrineQuestionnaire } from "../questionnaire/other-medical-conditions/EndocrineQuestionnaire.component";
import { CancerTumourQuestionnaire } from "../questionnaire/other-medical-conditions/CancerTumourQuestionnaire.component";
import { EyeHearingSpeechQuestionnaire } from "../questionnaire/other-medical-conditions/EyeHearingSpeechQuestionnaire.component";
import { GallbladderQuestionnaire } from "../questionnaire/other-medical-conditions/GallbladderQuestionnaire.component";
import { SevereInjuryQuestionnaire } from "../questionnaire/other-medical-conditions/SevereInjuryQuestionnaire.component";
import { HeartQuestionnaire } from "../questionnaire/other-medical-conditions/HeartQuestionnaire.component";
import { OtherIllnessQuestionnaire } from "../questionnaire/other-medical-conditions/OtherIllnessQuestionnaire.component";

import { OtherInformationQuestionnaire } from "../questionnaire/other-information/OtherInformationQuestionnaire.component";

import { selectHealthDeclarationOfUsers } from "../../../redux/health-declaration/hdf.selectors";
import { questionnairesStyles } from "./Questionnaires.styles";

export const QuestionnairesComponent = props => {
  const { user } = props;

  const {
    subQuestionContainer,
    accordionBadge,
    userHeaderTable,
    toggleComponentTable,
    toggleComponentTableCell,
    accordion,
    accordionTitle,
    subQuestionTitle,
    userHeaderTableRow,
    userHeaderTableCell
  } = questionnairesStyles();

  const dispatch = useDispatch();
  const healthDeclarationOfUsers = useSelector(selectHealthDeclarationOfUsers);

  const [switchValue, setSwitchValue] = useState(undefined);

  const [negativeValues, setNegativeValues] = useState([]);

  const [positiveValues, setPositiveValues] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(true);

  const [negativeValuesError, setNegativeValuesError] = useState([]);

  const [positiveValuesError, setPositiveValuesError] = useState([]);

  const [expandedBefore, setExpandedBefore] = useState([1]);

  const [noOfQuestions, setNoOfQuestions] = useState(0);

  const renderSubQuestionTitle = (title, subKey) => {
    if (subKey === "respiratory") {
      const respiratory = title.split("\n");
      return (
        <Typography>
          {respiratory[0]} <br />
          <br />
          {respiratory[1]}
        </Typography>
      );
    } else {
      return title;
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const updateExpandedBefore = id => (event, expanded) => {
    if (expanded) {
      let found = findExpandedBefore(id);
      if (!found) {
        setExpandedBefore([...expandedBefore, id]);
      }
    }
  };

  const setAccordionTitle = (title, key) => {
    return healthDeclarationOfUsers.length === 1 &&
      healthDeclarationOfUsers[0].person_type === "EMPLOYEE" &&
      key === "personalDetails"
      ? "Details of Employee"
      : title;
  };

  const findExpandedBefore = id => {
    let filtered = expandedBefore.find(e => {
      return e == id;
    });

    return filtered;
  };

  const checkIfFemaleUser = (key, healthDeclarationOfUser) => {
    return healthDeclarationOfUser.gender === "M" && key === "femaleInsuredOnly"
      ? true
      : false;
  };

  const selectQuestionnaireElement = value => {
    const {
      optionValue,
      userId,
      questionId,
      key,
      subKey
    } = getDifferentiatedValues(value);
    let questionKey = userId + "=" + questionId + "=" + key + "=" + subKey;

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "privateFlying"
    ) {
      return (
        <PrivateFlyingQuestionnaire
          key={questionKey}
          id={value}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "scubaDiving"
    ) {
      return (
        <ScubaDivingQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "mountaineering"
    ) {
      return (
        <MountaineeringQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "skyDiving"
    ) {
      return (
        <SkyDivingQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "racing"
    ) {
      return (
        <RacingQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "extremeSportsEngagement" &&
      subKey === "other"
    ) {
      return (
        <OtherExtremeSportsEngagementQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "drugAlcoholAddictionHistory") {
      return (
        <DrugAlcoholAddictionQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "insuranceArrangement") {
      return (
        <InsuranceArrangementQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "recentHealthCondition") {
      return (
        <RecentHealthConditionQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "smoking") {
      return (
        <SmokingHabitsQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "alcohol") {
      return (
        <AlcoholConsumptionQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "sexuallyTransmittedDiseases") {
      return (
        <STDsHistoryQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "healthScreeningHistory") {
      return (
        <HealthScreeningHistoryQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (optionValue === "Y" && key === "familyMedicalHistory") {
      return (
        <FamilyMedicalHistoryQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "femaleInsuredOnly" &&
      subKey === "papSmear"
    ) {
      return (
        <PAPSmearQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "femaleInsuredOnly" &&
      subKey === "gynecological"
    ) {
      return (
        <GynecologicalQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "femaleInsuredOnly" &&
      subKey === "pregnancySuffering"
    ) {
      return (
        <PregnancySufferingQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "femaleInsuredOnly" &&
      subKey === "otherProblemsInvolvingFemaleOrgans"
    ) {
      return (
        <OtherFemaleOrgansIssuesQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "femaleInsuredOnly" &&
      subKey === "pregnancyCondition"
    ) {
      return (
        <PregnancyConditionQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "respiratory"
    ) {
      return (
        <RespiratoryQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "nervousMental"
    ) {
      return (
        <NervousMentalQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "bowel"
    ) {
      return (
        <BowelQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "kidney"
    ) {
      return (
        <KidneyQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "endocrine"
    ) {
      return (
        <EndocrineQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "cancerTumour"
    ) {
      return (
        <CancerTumourQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "eyeHearingSpeech"
    ) {
      return (
        <EyeHearingSpeechQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "gallbladder"
    ) {
      return (
        <GallbladderQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "severeInjury"
    ) {
      return (
        <SevereInjuryQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "heart"
    ) {
      return (
        <HeartQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }

    if (
      optionValue === "Y" &&
      key === "otherMedicalConditions" &&
      subKey === "otherIllness"
    ) {
      return (
        <OtherIllnessQuestionnaire
          key={questionKey}
          id={value}
          users={healthDeclarationOfUsers}
          handleRequiredFieldsError={props.handleRequiredFieldsError}
        />
      );
    }
  };

  const settingTotalNoOfQuestion = () => {
    const initialQuestionCount = healthDeclarationOfUsers.length * 30;
    let maleUsers = 0;
    healthDeclarationOfUsers.map(user => {
      if (user.gender === "M") {
        maleUsers++;
      }
    });
    setNoOfQuestions(initialQuestionCount - maleUsers * 5);
  };

  const checkingYesNoValidation = totalAnsweredQuestions => {
    console.log(
      "number of questions answered : " +
        totalAnsweredQuestions +
        " / " +
        noOfQuestions
    );
    props.handleHDFValidation(noOfQuestions, totalAnsweredQuestions);
  };

  useEffect(() => {
    if (switchValue) {
      const {
        optionValue,
        userId,
        questionId,
        key,
        subKey
      } = getDifferentiatedValues(switchValue);

      const user = getUser(healthDeclarationOfUsers, userId);
      const userIndex = getUserIndex(healthDeclarationOfUsers, userId);

      let qns = user.qns ? user.qns : [];
      let questionKey = userId + "=" + questionId + "=" + key + "=" + subKey;

      let exist = qns.find(question => question.qid == questionKey);

      if (exist) {
        exist.qvalue = switchValue;
      } else {
        let qnsMap = {
          qid: questionKey,
          qvalue: switchValue
        };
        qns.push(qnsMap);
      }

      // const modifiedValueToSearchForEntry =
      //   "Y=" + userId + "=" + questionId + "=" + key + "=" + subKey;

      if (optionValue === "N") {
        props.handleRequiredFieldsError(key, subKey, false);

        if (subKey !== undefined || subKey !== "undefined") {
          if (key === subKey) {
            const modifiedUser = { ...user, [key]: null, qns };

            const modifiedHealthDeclarationOfUsers = [
              ...healthDeclarationOfUsers
            ];
            modifiedHealthDeclarationOfUsers[userIndex] = modifiedUser;

            dispatch(
              handleUpdateHealthDeclaration(modifiedHealthDeclarationOfUsers)
            );
          } else {
            const keyObject = user[key];
            if (keyObject && keyObject.hasOwnProperty(subKey)) {
              delete keyObject[subKey];
            }

            const modifiedUser = { ...user, [key]: keyObject || null, qns };
            const modifiedHealthDeclarationOfUsers = [
              ...healthDeclarationOfUsers
            ];
            modifiedHealthDeclarationOfUsers[userIndex] = modifiedUser;

            dispatch(
              handleUpdateHealthDeclaration(modifiedHealthDeclarationOfUsers)
            );
          }
        }

        // const modifiedArray = positiveValues.filter(
        //   positiveValue =>
        //     !positiveValue.includes(modifiedValueToSearchForEntry)
        // );
        // setPositiveValues(modifiedArray);
      } else {
        // setPositiveValues([...positiveValues, switchValue]);
        const updatedUser = {
          ...user,
          qns
        };
        healthDeclarationOfUsers[userIndex] = updatedUser;
        dispatch(handleUpdateHealthDeclaration(healthDeclarationOfUsers));
      }
    }
  }, [switchValue]);

  useEffect(() => {
    settingTotalNoOfQuestion();

    let positiveValues = [];
    let negativeValues = [];

    healthDeclarationOfUsers.map(healthDeclarationOfUser => {
      let answeredqns = healthDeclarationOfUser.qns
        ? healthDeclarationOfUser.qns
        : [];

      let positive = answeredqns
        .filter(answer => answer.qvalue.startsWith("Y"))
        .map(answer => answer.qvalue);
      positiveValues = [...positiveValues, ...positive];

      let negative = answeredqns
        .filter(answer => answer.qvalue.startsWith("N"))
        .map(answer => answer.qvalue);
      negativeValues = [...negativeValues, ...negative];
    });

    setPositiveValues(positiveValues);
    setNegativeValues(negativeValues);

    setPositiveValuesError(positiveValues);
    setNegativeValuesError(negativeValues);

    dispatch(handleUpdateHasHDF(true));
  }, []);

  useEffect(() => {
    checkingYesNoValidation(
      negativeValuesError.length + positiveValuesError.length
    );
  }, [noOfQuestions]);

  // const [modArr, setModArr] = useState([]);

  // useEffect(() => {
  //   switch (noCounter) {
  //     case 1:
  //       setModArr(positiveValues);
  //       setPositiveValues([]);
  //       setNoCounter(2);
  //       break;
  //     case 2:
  //       setPositiveValues(modArr);
  //       setNoCounter(0);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [positiveValues]);

  const handleSwitch = event => {
    setSwitchValue(event.target.value);
    const switchValue = event.target.value;
    const {
      optionValue,
      userId,
      questionId,
      key,
      subKey
    } = getDifferentiatedValues(switchValue);
    const modifiedValueToSearchForEntry =
      "Y=" + userId + "=" + questionId + "=" + key + "=" + subKey;

    if (optionValue === "N") {
      let index = positiveValuesError.indexOf(modifiedValueToSearchForEntry);

      if (index > -1) {
        positiveValuesError.splice(index, 1);
        setPositiveValuesError(positiveValuesError);
      }

      negativeValuesError.push(switchValue);
      setNegativeValuesError(negativeValuesError);

      checkingYesNoValidation(
        negativeValuesError.length + positiveValuesError.length
      );
    } else {
      const modifiedNegativeToSearchForEntry =
        "N=" + userId + "=" + questionId + "=" + key + "=" + subKey;

      let index = negativeValuesError.indexOf(modifiedNegativeToSearchForEntry);

      if (index > -1) {
        negativeValuesError.splice(index, 1);
        setNegativeValuesError(negativeValuesError);
      }

      positiveValuesError.push(switchValue);
      setPositiveValuesError(positiveValuesError);
      checkingYesNoValidation(
        negativeValuesError.length + positiveValuesError.length
      );
    }
  };

  const renderTableHeader = () => {
    let hasEmployee = false;
    let hasSpouse = false;
    let hasChildren = false;

    {
      //settingTotalNoOfQuestion();
      healthDeclarationOfUsers.map(({ id, full_name, person_type }) => {
        if (person_type === "EMPLOYEE") {
          hasEmployee = true;
        } else if (person_type === "SPOUSE") {
          hasSpouse = true;
        } else if (person_type === "CHILD") {
          hasChildren = true;
        }
      });
    }

    if (healthDeclarationOfUsers.length > 0) {
      return (
        <Box>
          <Box>
            <Typography
              fontWeight={"bold"}
              sx={{ color: "red", paddingLeft: "62px", paddingTop: "32" }}
            >
              Health Declaration
            </Typography>
            {(hasEmployee && (hasSpouse || hasChildren)) ||
            hasSpouse ||
            hasChildren ? (
              <Typography
                fontWeight={"bold"}
                sx={{ lineHeight: 4, paddingLeft: "62px" }}
              >
                Please complete the form for yourself and/or your dependent(s)
                respectively. Ensure that your spouse is physically present to
                complete eSignature at the next step.
              </Typography>
            ) : (
              <Typography
                fontWeight={"bold"}
                sx={{ lineHeight: 4, paddingLeft: "62px" }}
              >
                Please complete the form and the eSignature at the next step.
              </Typography>
            )}
            <Typography
              fontWeight={"bold"}
              sx={{ lineHeight: 1, paddingLeft: "62px" }}
            >
              All information disclosed will be strictly confidential only for
              perusal and consideration of your application.
            </Typography>
            <Typography
              fontWeight={"bold"}
              sx={{ lineHeight: 2, paddingLeft: "62px" }}
            >
              Note: If you have answered "Yes" to any of the following questions
              below, additional information will be requested.
            </Typography>
            <TextField
              InputProps={{
                readOnly: true,
                cursor: "default"
              }}
              defaultValue={props.user.client_name.config.companyName}
              disabled={true}
              sx={{
                marginLeft: "62px",
                marginBottom: "32px",
                marginTop: "22px"
              }}
              id="standard-basic"
              label="Company Name"
              variant="standard"
            />
            <TextField
              InputProps={{
                readOnly: true,
                cursor: "default"
              }}
              defaultValue={props.user.policy_no}
              disabled={true}
              sx={{
                marginLeft: "20px",
                marginBottom: "32px",
                marginTop: "22px"
              }}
              id="standard-basic"
              label="Group Policy"
              variant="standard"
            />
          </Box>
          <Table>
            <TableHead>
              <TableRow className={userHeaderTableRow}>
                {healthDeclarationOfUsers.map(
                  ({ id, full_name, person_type }) => {
                    return (
                      <TableCell key={id} className={userHeaderTableCell}>
                        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ===
                        true
                          ? ""
                          : full_name}
                        <br />
                        {isOnlyAndMainEmployee(healthDeclarationOfUsers) ===
                        true
                          ? ""
                          : person_type === "EMPLOYEE"
                          ? "(Self)"
                          : person_type === "SPOUSE"
                          ? "(Spouse)"
                          : "(Child)"}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      );
    }
  };

  const renderToggleComponent = (questionId, key, subKey) => {
    if (key === "femaleInsuredOnly") {
      return (
        <Table className={toggleComponentTable}>
          <TableRow>
            {healthDeclarationOfUsers.map(healthDeclarationOfUser => {
              const { id: userId, gender, qns } = healthDeclarationOfUser;

              let value = "";

              if (qns) {
                let questionKey =
                  userId + "=" + questionId + "=" + key + "=" + subKey;
                let exist = qns.find(question => question.qid == questionKey);
                value = exist?.qvalue;
              }

              return gender === "F" ? (
                <TableCell key={userId} className={toggleComponentTableCell}>
                  <RadioGroup
                    name="radio-buttons-group"
                    required
                    value={value}
                    // defaultValue={`N=${userId}=${questionId}=${key}=${subKey}`}
                    onChange={handleSwitch}
                  >
                    <FormControlLabel
                      id={`Y=${userId}=${questionId}=${key}=${subKey}`}
                      value={`Y=${userId}=${questionId}=${key}=${subKey}`}
                      disabled={checkIfFemaleUser(key, healthDeclarationOfUser)}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: red[500]
                            }
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      id={`N=${userId}=${questionId}=${key}=${subKey}`}
                      value={`N=${userId}=${questionId}=${key}=${subKey}`}
                      disabled={checkIfFemaleUser(key, healthDeclarationOfUser)}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: red[500]
                            }
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </TableCell>
              ) : (
                <TableCell key={userId} className={toggleComponentTableCell}>
                  <RadioGroup name="radio-buttons-group" disabled>
                    <FormControlLabel
                      disabled={true}
                      control={<Radio disable={true} value="" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={true}
                      control={<Radio disable={true} value="" />}
                      label="No"
                    />
                  </RadioGroup>
                </TableCell>
              );
            })}
          </TableRow>
        </Table>
      );
    } else {
      return (
        <Table className={toggleComponentTable}>
          <TableRow>
            {healthDeclarationOfUsers.map(healthDeclarationOfUser => {
              const { id: userId, qns } = healthDeclarationOfUser;

              let value = "";
              if (qns) {
                let questionKey =
                  userId + "=" + questionId + "=" + key + "=" + subKey;
                let exist = qns.find(question => question.qid == questionKey);
                value = exist?.qvalue;
              }

              return (
                <TableCell key={userId} className={toggleComponentTableCell}>
                  <RadioGroup
                    name="radio-buttons-group"
                    required
                    value={value}
                    // defaultValue={`N=${userId}=${questionId}=${key}=${subKey}`}
                    onChange={handleSwitch}
                  >
                    <FormControlLabel
                      id={`Y=${userId}=${questionId}=${key}=${subKey}`}
                      value={`Y=${userId}=${questionId}=${key}=${subKey}`}
                      disabled={checkIfFemaleUser(key, healthDeclarationOfUser)}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: red[500]
                            }
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      id={`N=${userId}=${questionId}=${key}=${subKey}`}
                      value={`N=${userId}=${questionId}=${key}=${subKey}`}
                      disabled={checkIfFemaleUser(key, healthDeclarationOfUser)}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: red[500]
                            }
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </TableCell>
              );
            })}
          </TableRow>
        </Table>
      );
    }
  };

  console.log("QuestionnairesComponent", user);

  return (
    <Box>
      <SimpleMessageDialog
        type="none"
        isHtml={true}
        centerClose={true}
        description='<span style="font-weight: bold;">Please note that logging out will reset Health Declaration Form.</span>'
        closeButtonText="Close"
        closeHandler={handleClose}
        open={
          dialogOpen && user.client_name.config.isPruAgencyEnrolment === "True"
        }
      />
      {renderTableHeader()}
      {Questionnaires.map((Questionnaire, index) => {
        const { id, title, subQuestions, key } = Questionnaire;

        if (
          key === "femaleInsuredOnly" &&
          !hdfUserListHasFemale(healthDeclarationOfUsers)
        ) {
          return <></>;
        } else {
          return (
            <Accordion
              key={id}
              className={accordion}
              defaultExpanded={id === 1}
              onChange={updateExpandedBefore(id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={accordionTitle} id="accordion-header">
                  <ThemeProvider theme={badgeTheme}>
                    <Badge
                      className={accordionBadge}
                      badgeContent={index + 1}
                      color={findExpandedBefore(id) ? "primary" : "secondary"}
                    />
                  </ThemeProvider>
                  <Typography display={"inline"} fontWeight={"bold"}>
                    {setAccordionTitle(title, key)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {key != "personalDetails" && subQuestions.length > 0 ? (
                  subQuestions.map(subQuestion => {
                    const { id, title, key: subKey } = subQuestion;
                    return (
                      <Box key={id} className={subQuestionContainer}>
                        <Typography className={subQuestionTitle}>
                          {renderSubQuestionTitle(title, subKey)}
                        </Typography>
                        {/* <Typography>Toggle for Sub Question</Typography> */}
                        {renderToggleComponent(id, key, subKey)}
                        {positiveValues.length > 0 &&
                          positiveValues.map(positiveValue => {
                            const {
                              subKey: positiveValueSubKey
                            } = getDifferentiatedValues(positiveValue);
                            return (
                              subKey === positiveValueSubKey &&
                              selectQuestionnaireElement(positiveValue)
                            );
                          })}
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    {/* <Typography>Toggle for Main Question</Typography> */}
                    {key === "personalDetails" ? (
                      <OtherInformationQuestionnaire
                        userDetails={props.user}
                        handleRequiredFieldsError={
                          props.handleRequiredFieldsError
                        }
                      />
                    ) : (
                      <Box>
                        {renderToggleComponent(id, key)}
                        {positiveValues.length > 0 &&
                          positiveValues.map(positiveValue => {
                            const {
                              key: positiveValueKey
                            } = getDifferentiatedValues(positiveValue);
                            return (
                              key === positiveValueKey &&
                              selectQuestionnaireElement(positiveValue)
                            );
                          })}
                      </Box>
                    )}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </Box>
  );
};

export const badgeTheme = createTheme({
  palette: {
    primary: {
      main: "#d32f2f",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#FFFFFF", // background
      contrastText: "#d32f2f" // text
    }
  }
});
