import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { userActions } from "../../../../../../actions";
import MessageFormat from "messageformat";
import Checkbox from "@mui/material/Checkbox";

class FillHDFForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hdfData: props.user.hdfData,
      dependents: props.user.dependencies,
      value: "",
      content: props.user.content,
      placeholderMap: props.user.placeholderMap,
      selectedValue: new Map(this.props.user.selectedValue),
      questionDepMap: new Map(this.props.user.questionDepMap),
      radioCount: 0,
      expanded: false,
      hdfConsent: props.user.hdfConsent || false,
      errors: new Map(),
      showUWEmail: false
    };
    // this.handleChangeRadio = this.handleChangeRadio.bind(this);
  }

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };
  showButton = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  handleChangeConsent = name => event => {
    var isConsent = event.target.checked;
    this.setState({ ...this.state, [name]: isConsent });
    this.props.hdfConsent(isConsent);
    // this.props.handleNextEnable(radioBtnSelected && this.state.hdfConsent);
  };

  getType = (type, code, userId) => {
    if (type === "Radio") {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Response"
            name="response"
            value={
              (this.state.questionDepMap &&
                this.state.questionDepMap.get(userId) &&
                this.state.questionDepMap.get(userId).get(code)) ||
              ""
            }
            onChange={event => this.handleChangeRadio(event, code, userId)}
            color="primary"
          >
            <FormControlLabel
              value="Y"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              value="N"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "TextArea") {
      return (
        <div>
          <TextField
            style={{ overflow: "auto" }}
            label={this.getRemarksLabel(code, userId)}
            multiline={true}
            rows="9"
            fullWidth={true}
            onChange={event => this.handleChangeRemarks(event, code, userId)}
            onBlur={event => this.onBlurRemarks(event, code, userId)}
            defaultValue={this.getTextAreaDefault(code, userId)}
            value={
              this.state.selectedValue.get(userId) &&
              this.state.selectedValue.get(userId).get(code)
            }
          />
          {this.state.errors &&
            this.state.errors.get(userId) &&
            this.state.errors.get(userId).get(code) === true && (
              <Typography component="span" style={{ color: "red" }}>
                This field is required
              </Typography>
            )}
        </div>
      );
    }
  };

  getExpander(dependent, keyDep) {
    const { classes, history, user } = this.props;
    let defaultExpandDep =
      this.props.user.payloadEnrolment.coverage_details.current.length === 0
        ? true
        : false;
    if (defaultExpandDep && dependent.dependent === "SPOUSE" && keyDep === 0) {
      defaultExpandDep = true;
    } else if (
      defaultExpandDep &&
      dependent.dependent === "CHILD" &&
      keyDep === 0
    ) {
      defaultExpandDep = true;
    } else {
      defaultExpandDep = false;
    }
    return (
      <ExpansionPanel
        className={classes.expanded}
        key={keyDep}
        defaultExpanded={defaultExpandDep}
      >
        <ExpansionPanelSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography component={"span"} className={classes.summaryTitle}>
            <b>
              {dependent.full_name} (
              {this.capitalizeFLetter(dependent.dependent)})
            </b>{" "}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container style={{ padding: "10px" }}>
            {this.state.hdfData.questions.map(function(question, keyQDep) {
              let showQuestion = false;
              let elegibility = this.state.hdfData.details.find(
                detail => detail.code === question
              ).eligibility;
              this.radioCount.depCount = this.radioCount.depCount + 1;
              if (elegibility.gender === "ALL") showQuestion = true;
              else if (
                elegibility.gender === "Male" &&
                dependent.gender === "M"
              )
                showQuestion = true;
              else if (
                elegibility.gender === "Female" &&
                dependent.gender === "F"
              )
                showQuestion = true;
              if (!showQuestion)
                this.radioCount.depCount = this.radioCount.depCount - 1;
              return (
                showQuestion && (
                  <Fragment key={keyQDep}>
                    <Grid item xs={10} sm={10} md={10}>
                      <Card className={classes.cardQuestion}>
                        <CardContent>
                          <Typography
                            component={"span"}
                            className={classes.questionText}
                          >
                            Q{keyQDep + 1}){" "}
                            {
                              this.state.hdfData.details.find(
                                detail => detail.code === question
                              ).question
                            }
                            {/* {question} */}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} > */}
                    {/* <Grid container style={{background:'#F6F6F6'}}>                           */}
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ borderRight: "1px solid #E4E3E4" }}
                    >
                      <Card className={classes.cardResponse}>
                        <CardContent
                          classes={{ root: classes.cardRoot }}
                          style={{ paddingLeft: "0px", paddingRight: "0px" }}
                        >
                          {this.getType("Radio", question, dependent.id)}
                        </CardContent>
                      </Card>
                    </Grid>
                    {this.state.questionDepMap.get(dependent.id) &&
                      this.state.questionDepMap
                        .get(dependent.id)
                        .get(question) === "Y" && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ borderRight: "1px solid #E4E3E4" }}
                        >
                          <Card
                            className={classes.cardResponse}
                            style={{ borderTop: "none" }}
                          >
                            <CardContent
                              classes={{ root: classes.cardRoot }}
                              style={{ fontSize: "16px" }}
                            >
                              {this.getType("TextArea", question, dependent.id)}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    {/* </Grid> */}
                    {/* // </Grid>   */}
                  </Fragment>
                )
              );
            }, this)}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  getRows(code, userId) {
    let row;
    this.state.hdfData.details
      .find(detail => detail.code === code)
      .allowedValues.find(val => val.value === "Y")
      .dependentQuestions.map(function(depQ) {
        row = this.state.hdfData.details.find(detail => detail.code === depQ)
          .allowedValues.length;
      }, this);
    return row;
  }

  getRemarksLabel(code, userId) {
    let textDefault = ``;
    this.state.hdfData.details
      .find(detail => detail.code === code)
      .allowedValues.find(val => val.value === "Y")
      .dependentQuestions.map(function(depQ) {
        let remarkQ = this.state.hdfData.details.find(
          detail => detail.code === depQ
        );
        textDefault = remarkQ && remarkQ.question;
      }, this);
    return textDefault;
  }

  getTextAreaDefault(code, userId) {
    let textDefault = ``;
    this.state.hdfData.details
      .find(detail => detail.code === code)
      .allowedValues.find(val => val.value === "Y")
      .dependentQuestions.map(function(depQ) {
        this.state.hdfData.details
          .find(detail => detail.code === depQ)
          .allowedValues.map(function(value) {
            textDefault = textDefault + value.value + "\n";
          }, this);
      }, this);
    return textDefault;
  }

  handleChangeRadio = (event, code, userId) => {
    let radioBtnSelected = false;
    let questionDepMap = new Map(this.state.questionDepMap);
    let questionSet = new Map(this.state.questionDepMap.get(userId));
    let selectedValue = new Map(this.state.selectedValue);
    let showUWEmail = false;
    if (questionSet.has(code)) questionSet.delete(code);
    questionSet.set(code, event.target.value);
    questionDepMap.set(userId, questionSet);
    let radioSize = 0;
    questionDepMap.forEach(function(value, key, map) {
      radioSize = radioSize + value.size;
    });

    if (radioSize === this.radioCount.empCount + this.radioCount.depCount)
      radioBtnSelected = true;

    // if(event.target.value === "N"){
    // let selectedValue = new  Map(this.state.selectedValue);
    let remarkSet = new Map(this.state.selectedValue.get(userId));
    let question = remarkSet.get(code);
    let eraseRemarks = this.getTextAreaDefault(code, userId);
    if (remarkSet.has(code)) remarkSet.delete(code);
    remarkSet.set(code, eraseRemarks);
    selectedValue.set(userId, remarkSet);
    questionDepMap.forEach((value, key, map) => {
      value.forEach((value, key, map) => {
        if ("Y" === value) {
          showUWEmail = true;
        }
      });
    });

    this.setState(
      {
        ...this.state,
        questionDepMap: questionDepMap,
        selectedValue: selectedValue,
        showUWEmail: showUWEmail
      },
      () => {
        this.props.selectHDFValue(questionDepMap);
        this.props.selectHDFRemarks(selectedValue);
        this.props.handleNextEnable(radioBtnSelected);
      }
    );
  };

  onBlurRemarks = (event, code, userId) => {
    let errors = new Map(this.state.errors);
    let errorSet = new Map(this.state.errors.get(userId));
    let questionDepMap = new Map(this.state.questionDepMap);
    if (event.target.value.trim() === "") {
      errorSet.set(code, true);
      errors.set(userId, errorSet);
    } else {
      errorSet.delete(code);
      errors.set(userId, errorSet);
    }
    let errorCount = 0;
    let errorEnable = false;
    errors.forEach(function(value, key, map) {
      errorCount = errorCount + value.size;
    });
    if (errorCount === 0) errorEnable = true;

    let radioSize = 0;
    let radioBtnSelected = false;
    questionDepMap.forEach(function(value, key, map) {
      radioSize = radioSize + value.size;
    });

    if (radioSize === this.radioCount.empCount + this.radioCount.depCount)
      radioBtnSelected = true;

    this.setState(
      {
        ...this.state,
        errors: errors
      },
      () => {
        this.props.handleNextEnable(radioBtnSelected && errorEnable);
      }
    );
  };

  handleChangeRemarks = (event, code, userId) => {
    let selectedValue = new Map(this.state.selectedValue);
    let remarkSet = new Map(this.state.selectedValue.get(userId));
    if (remarkSet.has(code)) remarkSet.delete(code);
    remarkSet.set(code, event.target.value);
    selectedValue.set(userId, remarkSet);
    this.setState(
      {
        ...this.state,
        selectedValue: selectedValue
      },
      () => {
        this.props.selectHDFRemarks(selectedValue);
      }
    );
  };

  checkDependentHDF = dependent => {
    let planSelectedLen = this.props.user.payloadEnrolment.dependants.find(
      dep => dep.id === dependent.id
    ).coverage_details.current.length;
    if (planSelectedLen > 0) return true;
    else return false;
  };

  componentWillMount() {
    let radioBtnSelected = false;
    this.props.handleNextEnable(radioBtnSelected);
    let selectedValue = new Map(this.props.user.selectedValue);
    let questionDepMap = new Map(this.props.user.questionDepMap);
    let questionDepMapUpdated = new Map();
    let selectedValueUpdated = new Map();
    questionDepMap.forEach((value, key, map) => {
      if (Array.isArray(value)) {
        let arrayToMapVal = value.reduce(function(allQuestion, question) {
          allQuestion.set(question[0], question[1]);
          return allQuestion;
        }, new Map());
        questionDepMapUpdated.set(key, arrayToMapVal);
      } else {
        questionDepMapUpdated.set(key, value);
      }
    });
    selectedValue.forEach((value, key, map) => {
      if (Array.isArray(value)) {
        let arrayToMapVal = value.reduce(function(allQuestion, question) {
          allQuestion.set(question[0], question[1]);
          return allQuestion;
        }, new Map());
        selectedValueUpdated.set(key, arrayToMapVal);
      } else {
        selectedValueUpdated.set(key, value);
      }
    });

    let radioSize = 0;
    questionDepMapUpdated.forEach(function(value, key, map) {
      radioSize = radioSize + value.size;
    });
    if (radioSize === this.radioCount.empCount + this.radioCount.depCount)
      radioBtnSelected = true;
    this.setState(
      {
        selectedValue: selectedValueUpdated,
        questionDepMap: questionDepMapUpdated
      },
      () => {
        this.props.handleNextEnable(radioBtnSelected);
      }
    );
  }
  componentDidMount() {
    let radioBtnSelected = false;
    this.props.handleNextEnable(radioBtnSelected);
    let showUWEmail = false;
    let radioSize = 0;
    let selectedValue = new Map(this.props.user.selectedValue);
    let questionDepMap = new Map(this.props.user.questionDepMap);
    let questionDepMapUpdated = new Map();
    let selectedValueUpdated = new Map();
    questionDepMap.forEach((value, key, map) => {
      if (Array.isArray(value)) {
        let arrayToMapVal = value.reduce(function(allQuestion, question) {
          allQuestion.set(question[0], question[1]);
          return allQuestion;
        }, new Map());
        questionDepMapUpdated.set(key, arrayToMapVal);
      } else {
        questionDepMapUpdated.set(key, value);
      }
    });
    selectedValue.forEach((value, key, map) => {
      if (Array.isArray(value)) {
        let arrayToMapVal = value.reduce(function(allQuestion, question) {
          allQuestion.set(question[0], question[1]);
          return allQuestion;
        }, new Map());
        selectedValueUpdated.set(key, arrayToMapVal);
      } else {
        selectedValueUpdated.set(key, value);
      }
    });

    questionDepMapUpdated.forEach((value, key, map) => {
      value.forEach((value, key, map) => {
        if ("Y" === value) {
          showUWEmail = true;
        }
      });
    });

    questionDepMapUpdated.forEach(function(value, key, map) {
      radioSize = radioSize + value.size;
    });
    if (radioSize === this.radioCount.empCount + this.radioCount.depCount)
      radioBtnSelected = true;
    this.setState(
      {
        selectedValue: selectedValueUpdated,
        questionDepMap: questionDepMapUpdated,
        showUWEmail: showUWEmail
      },
      () => {
        this.props.handleNextEnable(radioBtnSelected);
      }
    );
  }

  radioCount = { empCount: 0, depCount: 0 };

  render() {
    this.radioCount = { empCount: 0, depCount: 0 };
    const { classes, history, user } = this.props;
    const { labels } = this.props.user.content;
    const { content, placeholderMap, expanded } = this.state;
    const mf = new MessageFormat("en");
    const header = mf.compile(labels["step3.header"]);
    const description = mf.compile(labels["step3.description"]);
    const footer = mf.compile(labels["step3.footer"]);
    const placeholderMapTemp = new Map(placeholderMap);
    return (
      <div className={classes.rootInfo}>
        <div className={classes.contentInfo}>
          <div style={{ padding: "0px 20px" }}>
            <div className={classes.headerInfo}>
              <Typography
                component={"span"}
                className={classes.headerText}
                dangerouslySetInnerHTML={{
                  __html:
                    header && header({ year: placeholderMapTemp.get("{year}") })
                }}
              />
            </div>
            <div>
              {this.props.user.payloadEnrolment.coverage_details.current
                .length > 0 && (
                <ExpansionPanel
                  className={classes.expanded}
                  defaultExpanded={true}
                >
                  <ExpansionPanelSummary
                    className={classes.panelSummary}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography
                      component={"span"}
                      className={classes.summaryTitle}
                    >
                      <b>{user.fullName} (Self)</b>{" "}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container style={{ padding: "10px" }}>
                      {this.state.hdfData.questions.map(function(
                        question,
                        keyQ
                      ) {
                        let showQuestion = false;
                        let elegibility = this.state.hdfData.details.find(
                          detail => detail.code === question
                        ).eligibility;
                        this.radioCount.empCount = this.radioCount.empCount + 1;

                        if (elegibility.gender === "ALL") showQuestion = true;
                        else if (
                          elegibility.gender === "Male" &&
                          user.gender === "M"
                        )
                          showQuestion = true;
                        else if (
                          elegibility.gender === "Female" &&
                          user.gender === "F"
                        )
                          showQuestion = true;
                        if (!showQuestion)
                          this.radioCount.empCount =
                            this.radioCount.empCount - 1;
                        return (
                          showQuestion && (
                            <Fragment key={keyQ}>
                              <Grid item xs={10} sm={10} md={10}>
                                <Card className={classes.cardQuestion}>
                                  <CardContent>
                                    <Typography
                                      component={"span"}
                                      className={classes.questionText}
                                    >
                                      Q{keyQ + 1}){" "}
                                      {
                                        this.state.hdfData.details.find(
                                          detail => detail.code === question
                                        ).question
                                      }
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={2}
                                style={{ borderRight: "1px solid #E4E3E4" }}
                              >
                                <Card className={classes.cardResponse}>
                                  <CardContent
                                    classes={{ root: classes.cardRoot }}
                                    style={{
                                      paddingLeft: "0px",
                                      paddingRight: "0px"
                                    }}
                                  >
                                    {this.getType("Radio", question, user.id)}
                                  </CardContent>
                                </Card>
                              </Grid>
                              {this.state.questionDepMap.get(user.id) &&
                                this.state.questionDepMap
                                  .get(user.id)
                                  .get(question) === "Y" && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ borderRight: "1px solid #E4E3E4" }}
                                  >
                                    <Card
                                      className={classes.cardResponse}
                                      style={{ borderTop: "none" }}
                                    >
                                      <CardContent
                                        classes={{ root: classes.cardRoot }}
                                        style={{ fontSize: "16px" }}
                                      >
                                        {this.getType(
                                          "TextArea",
                                          question,
                                          user.id
                                        )}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                )}
                            </Fragment>
                          )
                        );
                      },
                      this)}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
              {this.state.dependents
                .filter(dependent => dependent.dependent === "SPOUSE")
                .map(function(dependent, keyDep) {
                  return (
                    this.checkDependentHDF(dependent) &&
                    this.getExpander(dependent, keyDep)
                  );
                }, this)}
              {this.state.dependents
                .filter(dependent => dependent.dependent === "CHILD")
                .map(function(dependent, keyDep) {
                  return (
                    this.checkDependentHDF(dependent) &&
                    this.getExpander(dependent, keyDep)
                  );
                }, this)}
            </div>
          </div>
          {this.state.showUWEmail && (
            <Typography
              component={"span"}
              className={classes.note}
              dangerouslySetInnerHTML={{
                __html: footer({
                  underwriterEmail: placeholderMapTemp.get("{underwriterEmail}")
                })
              }}
            />
          )}
        </div>

        <div className={classes.bottomBackgroud}>
          <div className={classes.headerInfo}>
            <div className={classes.noteBlock} style={{ padding: "0px" }}>
              <Typography
                component={"span"}
                className={classes.noteHead}
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Consent, Declaration and Authorisation – Please read carefully
                before signing this Group Health Declaration Form.
              </Typography>
              <Typography component={"span"} className={classes.noteHead}>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <span>
                    I consent to Prudential Assurance Company (Pte) Limited
                    (“Prudential”), its officers and employees:
                    <br />
                    <br />
                    a) Collecting and using at their sole discretion any and all
                    information relating to me, including my personal
                    particulars in this Group Health Declaration for the
                    purposes of underwriting; and
                    <br />
                    <br />
                    b) Disclosing at their sole discretion any and all
                    information relating to me, including my personal
                    particulars in this Group Insurance Health Declaration to
                    the servicing intermediary for the above group policy for
                    the purpose of customer service.
                    <br /> <br />
                    I declare that no material facts, that are facts likely to
                    influence the assessment and acceptance of my group
                    application, have been withheld and the information given
                    above is true and complete and best to my knowledge and they
                    shall be the basis of product issuance of my group insurance
                    coverage.
                    <br />
                    <br />
                    I agree to inform Prudential if there is any change in the
                    state of my health/activity between the date of this Health
                    Declaration or medical examination and the date of full
                    insurance coverage is provided by Prudential to me. I
                    understand that the terms of accepting me as a risk for
                    insurance coverage may vary according to such information
                    received.
                    <br />
                    <br />I agree and authorize any medical source (i.e.
                    physician and hospital), insurance office or organization
                    that has my records to release to Prudential any relevant
                    information at any time for the purpose of underwriting this
                    group application. A photographic copy of this authorization
                    shall be as valid as the original.
                  </span>
                </div>
              </Typography>
            </div>
            <div className={classes.noteBlock}>
              <Typography
                component={"span"}
                className={classes.noteHead}
                style={{ fontWeight: "bold" }}
              >
                <Checkbox
                  checked={this.state.hdfConsent}
                  onChange={this.handleChangeConsent("hdfConsent")}
                  value="hdfConsent"
                  color="primary"
                />
                I have read and agreed to the information above.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  contentInfo: {
    paddingTop: 10,
    paddingBottom: 20,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerInfo: {
    padding: theme.spacing.unit * 2.5
  },
  questionText: {
    fontSize: "16px"
  },
  headerText: {
    color: "#ED1C24",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px"
  },
  cardRoot: {
    paddingBottom: "8px",
    paddingTop: "8px",
    textAlign: "center",
    [theme.breakpoints.down("xs", "sm")]: {
      paddingBottom: "8px",
      paddingTop: "8px"
    },
    "&:last-child": {
      paddingBottom: "8px"
    }
  },
  cardQuestion: {
    height: "100%",
    border: "1px solid #C5C4C4",
    boxShadow: "none",
    background: "rgba(228, 227, 228, 0.7)"
    // boxSizing: 'border-box',
    // borderRadius: '4px 4px 0px 0px'
  },
  cardResponse: {
    height: "100%",
    border: "1px solid #C5C4C4",
    boxShadow: "none",
    background: "#FFFFFF"
    // background:'rgb(246, 246, 246)'
    // boxSizing: 'border-box',
    // borderRadius: '4px 4px 0px 0px'
  },
  note: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#ED1C24",
    padding: "15px 45px"
  },
  bottomBackgroud: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px"
  },
  subHeader1Text: {
    color: "#6B6A6D",
    fontSize: "20px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center"
  },
  noteBlock: {
    padding: "20px 0px"
  },
  noteHead: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "28px"
  },
  summaryTitle: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FillHDFForm));
