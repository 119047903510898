import "./App.css";
import { BrowserRouter, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { renderRoutes } from "react-router-config";
import muiTheme from "../../themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Component } from "react";
import { getMessages } from "../../intl";
import routes from "../../routes";
import { LocalizationProvider as MuiDatePickerLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import BrowserDetection from "../../utils/BrowserDetection";
import config from "../../config/config";
import { createGenerateClassName, StylesProvider } from "@mui/styles";

const generateClassName = createGenerateClassName({
  productionPrefix: "tw"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "en"
    };
  }

  componentWillMount() {
    BrowserDetection.init();
  }

  render() {
    let i18Messages = getMessages(this.state.locale);
    let currentDateTime = new Date().getTime();
    let startDateTime = parseInt(config.startDateTime);
    let endDateTime = parseInt(config.endDateTime);
    let showMaintenace = false;
    const maintenancePath = "/maintenance";
    if (startDateTime < currentDateTime && endDateTime > currentDateTime) {
      showMaintenace = true;
    }

    return (
      <IntlProvider locale={this.state.locale} messages={i18Messages}>
        <MuiDatePickerLocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <StylesProvider generateClassName={generateClassName} injectFirst>
                <ThemeProvider theme={muiTheme}>
                  <CssBaseline />
                  <div className="App">{renderRoutes(routes)}</div>
                  {showMaintenace && config.maintenanceEnabled ? (
                    <Redirect to="/maintenance" />
                  ) : maintenancePath === window.location.pathname ? (
                    <Redirect to="/" />
                  ) : (
                    ""
                  )}
                </ThemeProvider>
              </StylesProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </MuiDatePickerLocalizationProvider>
      </IntlProvider>
    );
  }
}

export default App;
