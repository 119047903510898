import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Enums from "../../../utils/Enums";
import EnroleMyDetails from "../../../components/EnroleMyDetails";
import MessageFormat from "messageformat";
import AppBar from "@mui/material/AppBar";

const styles = theme => ({
  root: {
    height: "100%",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingBottom: 30
  },
  contentInfo: {
    paddingTop: 10,
    paddingBottom: 40,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  content: {
    paddingBottom: 40,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  header: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    },
    justifyContent: "space-between"
  },

  tabLabel: {
    fontSize: "18px",
    lineHeight: "40px",
    textAlign: "center"
  },
  tabLabelSelected: {
    fontSize: "18px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#ED1C24"
  },

  info: {
    background: "#F6F6F6",
    borderRadius: "0px 0px 4px 4px",
    padding: "0px 25px 0px 20px",
    fontSize: "16px"
  },
  infoText: {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 25px 0px"
  },

  indicator: {
    backgroundColor: "#ED1C24",
    color: "#ED1C24"
  },
  note: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6B6A6D",
    padding: "15px"
  },
  tabRoot: {
    textTransform: "initial"
  },
  selected: {
    color: "#000000",
    fontSize: 16,
    textAlign: "center",
    background: "white",
    border: "none",
    borderTop: "3px solid #ED1C24",
    fontWeight: 600
  },
  gridStyle1: {
    [theme.breakpoints.down("xs", "sm")]: {
      overflow: "scroll"
    }
  },
  tabsIndicator: {
    background: "#ffffff"
  }
});

class EnroleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      content: props.user.content,
      placeholderMap: props.user.placeholderMap,

      plans: {
        selectedTypes: [Enums.PLANS_TYPE.BENEFITS]
      }
    };
  }

  mappingStatus = stages => {
    let getStatus = this.Statuses.filter(status => {
      return status.mapTo.indexOf(stages) > -1;
    });
    return getStatus[0].status;
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.state;

    const mf = new MessageFormat("en");
    const note = mf.compile(labels["step1.note"]);
    const placeholderMapTemp = new Map(placeholderMap);
    const { classes, intl } = this.props;
    const styles = {
      avatar: {
        margin: 10
      }
    };
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.header} />
          <div className={classes.gridStyle1}>
            <AppBar position="static" color="default" elevation={0}>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                scrollable
                scrollButtons="auto"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator
                }}
              >
                <Tab
                  label="Your Details"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selected
                  }}
                  style={{ height: "90px" }}
                />
              </Tabs>
            </AppBar>
            {this.state.value === 0 && (
              <div>
                <EnroleMyDetails
                  title="Your Information"
                  type="Employee"
                  history={this.props.history}
                  buttonName={this.props.location.state}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.info}>
          <Typography
            component={"div"}
            className={classes.note}
            dangerouslySetInnerHTML={{
              __html: note({
                supportEmail: placeholderMapTemp.get("{supportEmail}"),
                supportBillingEmail: this.props.user.client_name.config
                  .supportBillingEmail
              })
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(EnroleDetails)));
